import { $themeBreakpoints } from "@themeConfig";
import axios from "@axios";
export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    pictureUrl: "",
    selectedPlantId: 0,
    selectedPlantName: "",
    companyPictureUrl: "",
    companyCredits: 0,
    errCount: 0,
    firstRequest: true,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    UPDATE_COMPANY_PICTURE_URL(state, val) {
      state.companyPictureUrl = val;
    },
    UPDATE_PICTURE_URL(state, val) {
      state.pictureUrl = val;
    },
    UPDATE_PLANT(state, val) {
      state.selectedPlantId = val;
    },
    UPDATE_COMPANY_CREDITS(state, val) {
      state.companyCredits = val;
    },
    SET_ERROR(state, val) {
      state.errCount = val;
    },
    SET_FIRST_REQUEST(state, val) {
      state.firstRequest = val;
    },
  },
  actions: {
    async fetchCompanyCredits(ctx, companyId) {
      const { data } = await axios.get("/Companies/GetCompanyCurrentCredits/");
      ctx.commit("UPDATE_COMPANY_CREDITS", data.resultdata);
      return data.resultdata;
    },
    setError(ctx, errCount) {
      ctx.commit("SET_ERROR", errCount);
    },
    setFirstRequest(ctx, firstRequest) {
      ctx.commit("SET_FIRST_REQUEST", firstRequest);
    },
  },
};
