import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export const templateState = { templateData: [], selectedTemplate: {} };

export const templateMutations = {
  SET_TEMPLATES(state, payload) {
    state.templateData = payload;
  },
  SET_TEMPLATE(state, payload) {
    state.selectedTemplate = payload;
  },
};
export const templateActions = {
  async fetchTemplates(ctx) {
    var response = await axios.get(`/Templates/GetTemplates`);
    if (response.data.status != "OK") return null;
    ctx.commit("SET_TEMPLATES", response.data.resultdata);
    return response.data.resultdata;
  },

  async fetchTemplate(ctx, templateId) {
    var response = await axios.get(`/Templates/GetTemplate/${templateId}`);
    if (response.data.status != "OK") return null;
    ctx.commit("SET_TEMPLATE", response.data.resultdata);
    return response.data.resultdata;
  },

  async addTemplate(ctx, template) {
    var response = await axios.post(
      `/Templates/AddTemplate`,
      JSON.stringify(template.templatename)
    );

    return response.data.status == "OK";
  },

  async updateTemplate(ctx, template) {
    var response = await axios.put(
      `/Templates/SaveTemplate/${template.id}`,
      template
    );
    return response.data.status == "OK";
  },

  async deleteTemplate(ctx, templateId) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Templates/DeleteTemplate/${templateId}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
};
