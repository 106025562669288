import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export const multiRationState = {
  multiFormula: {},
  selectedSingleFormula: {
    id: 0,
  },
  multiFormulaLoading: false,
  multiCozdu: null,
  multiFormulaOptions: {
    useMinProd: false,
    showInventoryCost: false,
  },
};

export const multiRationMutations = {
  SET_MULTI_FORMULAS(state, payload) {
    state.allFormulas = payload;
  },
  SET_MULTI_FORMULA(state, payload) {
    state.multiFormula = payload;
  },
  SET_LOADING(state) {
    state.multiFormulaLoading = !state.multiFormulaLoading;
  },
  SET_FORMULA_OPTIONS(state, { useMinProd, showInventoryCost }) {
    state.multiFormulaOptions.useMinProd =
      useMinProd ?? state.multiFormulaOptions.useMinProd;
    state.multiFormulaOptions.showInventoryCost =
      showInventoryCost ?? state.multiFormulaOptions.showInventoryCost;
  },
  SET_MULTI_COZDU(state, payload) {
    state.multiCozdu = payload;
  },
  SET_SELECTED_SINGLE_FORMULA(state, id) {
    if (id == 0) {
      state.selectedSingleFormula = { id: 0 };
      return;
    }

    const selectedFormulaIndex = state.multiFormula[0].formuller.findIndex(
      (formula) => formula.id == id
    );
    if (selectedFormulaIndex == -1) {
      state.selectedSingleFormula = { id: 0 };
      return;
    }
    state.selectedSingleFormula =
      state.multiFormula[0].formuller[selectedFormulaIndex];
  },
};
export const multiRationActions = {
  async postMultiRation(ctx, { formula, plantid }) {
    ctx.commit("SET_LOADING");
    let response = await axios.post("/Multi/AddMultiGroup", formula, {
      headers: {
        plantid: plantid,
        multiadi: formula.multiadi,
      },
    });
    ctx.commit("SET_LOADING");
    ctx.commit("SET_MULTI_FORMULA", response.data.resultdata);
    return response.data.status == "OK";
  },
  async updateMultiFormula(ctx, { formula, plantid }) {
    ctx.commit("SET_LOADING");
    let response = await axios.put(
      "/Multi/UpdateMultiGroupName/" + formula.id,
      formula,
      {
        headers: {
          plantid: plantid,
          multiadi: formula.Multiadi,
        },
      }
    );
    ctx.commit("SET_LOADING");
    return response.data.status == "OK";
  },
  async deleteMultiFormula(ctx, { formulaid, plantid, tur }) {
    var response = {};
    var response = await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          response = await axios.delete(
            `/Multi/DeleteMultiGroup/${formulaid}`,
            {
              headers: { plantid, tur },
            }
          );
          return response.data.status != "OK";
        } else return false;
      });
    if (response) {
      ctx.dispatch("toast", {
        msg: "successfull",
        type: "deleted",
        tur: "formula",
      });
    } else {
      ctx.dispatch("toast", {
        msg: "unsuccessful",
        type: "notDeleted",
        tur: "formula",
      });
    }
    return response;
  },
  async getMultiGroup(ctx, { plantid, multiid }) {
    ctx.commit("SET_LOADING");
    let response = await axios.get("/Multi/GetMultiGroup/" + multiid, {
      headers: {
        plantid: plantid,
      },
    });
    ctx.commit("SET_MULTI_FORMULA", response.data.resultdata);
    ctx.commit("SET_LOADING");
    return response.data;
  },
  async getFormulasForMultiGroup(ctx, { plantid, multiid }) {
    const ids = ctx.state.multiFormula[0]?.formuller
      ?.filter((f) => f.isselected !== false || f.isselected !== "false")
      .map((f) => f.id);
    ctx.commit("SET_LOADING");
    let response = await axios.get("/Multi/GetFormulasForMultiGroup", {
      headers: {
        plantid: plantid,
        multiid: multiid,
        ids: ids,
      },
    });
    ctx.commit("SET_SELECTED_SINGLE_FORMULA", 0);
    ctx.commit("SET_LOADING");
    return response.data.resultdata;
  },
  async updateFormulasforMultiGroup(ctx, { plantid, ids, multiid }) {
    ctx.commit("SET_LOADING");
    let response = await axios.put(
      "/Multi/UpdateFormulasforMultiGroup/" + multiid,
      ctx.state.multiFormula[0],
      {
        headers: {
          plantid: plantid,
          ids: ids,
        },
      }
    );
    ctx.commit("SET_MULTI_FORMULA", response.data.resultdata);
    ctx.commit("SET_LOADING");
    return response.data;
  },
  async saveMultiGroup(ctx, { plantid, multiid }) {
    ctx.commit("SET_LOADING");
    let response = await axios.put(
      "/Multi/SaveMultiGroup/" + multiid,
      ctx.state.multiFormula[0],
      {
        headers: {
          plantid: plantid,
        },
      }
    );
    ctx.commit("SET_LOADING");
    return response.data.status == "OK";
  },
  async updateMultiIngredients(ctx) {
    ctx.commit("SET_LOADING");
    if (ctx.state.selectedSingleFormula.id == 0) {
      let formulaHammaddeler = ctx.state.multiFormula[0].tumFormuller;
      let hammaddeler = ctx.state.multiFormula[0].hammaddeler;

      let selectedFormuller = ctx.state.allIngredients.filter(
        (ingredient) =>
          ingredient.selected == 1 &&
          !formulaHammaddeler.some((element) => element.ikey == ingredient.ikey)
      );
      let selectedHammaddeler = ctx.state.allIngredients.filter(
        (ingredient) =>
          ingredient.selected == 1 &&
          !hammaddeler.some((element) => element.ikey == ingredient.ikey)
      );

      let alreadyFormuller = ctx.state.allIngredients
        .map((ingredient) => {
          if (ingredient.selected == 1)
            return formulaHammaddeler.filter(
              (element) => element.ikey == ingredient.ikey
            );
        })
        .filter((e) => e && Object.keys(e).length)
        .flat();
      let alreadyHammaddeler = ctx.state.allIngredients
        .map((ingredient) => {
          if (ingredient.selected == 1)
            return hammaddeler.filter(
              (element) => element.id == ingredient.ikey
            );
        })
        .filter((e) => e && Object.keys(e).length)
        .flat();

      let tumFormuller = [
        ...alreadyFormuller,
        ...selectedFormuller.map((x) => ({
          ikey: x.ikey,
          id: x.ikey,
          hammadde: x.ingredientname,
          fiyat: x.price,
          min: x.min,
          max: x.max,
          karisim: 0,
          minfiyat: 0,
          maxfiyat: 0,
          oncekikarisim: 0,
          kayitlikarisim: 0,
          onerilenkarisim: 0,
          onerilenfiyat: 0,
          kayitlidanfark: 0,
          oncekindenfark: 0,
          kod: x.kod,
          siralikod: 0,
          stok: true,
          orjfiyat: x.price,
          oncekifiyat: 0,
          orjmin: x.min,
          oncekimin: 0,
          orjmax: x.max,
          oncekimax: 0,
        })),
      ];
      let tumHammaddeler = [
        ...alreadyHammaddeler,
        ...ctx.state.multiFormula[0].formuller.map((formul) => {
          return selectedHammaddeler.map((x) => {
            if (
              !ctx.state.multiFormula[0].hammaddeler.some(
                (element) => element.id == x.ikey
              )
            )
              return {
                id: x.ikey,
                rasyonid: formul.id,
                kod: x.kod,
                hammadde: x.ingredientname,
                min: x.min,
                max: x.max,
                fiyat: x.price,
                aktif: true,
                stok: true,
                karisim: x.karisim,
                minproduction: 0,
                minfiyat: 0,
                maxfiyat: 0,
                oncekikarisim: 0,
                kayitlikarisim: 0,
                onerilenkarisim: 0,
                onerilenfiyat: 0,
                kayitlidanfark: 0,
                oncekindenfark: 0,
                orjkarisim: x.karisim,
                ilkkarisim: x.karisim,
                orjfiyat: x.price,
                oncekifiyat: 0,
                orjmin: x.min,
                oncekimin: 150,
                orjmax: x.max,
                oncekimax: 400,
                rasyonkodu: formul.code,
              };
          });
        }),
      ];

      ctx.state.multiFormula[0].tumFormuller = tumFormuller;
      ctx.state.multiFormula[0].hammaddeler = tumHammaddeler
        .flat()
        .filter((x) => x);
    } else {
      let hammaddeler = ctx.state.multiFormula[0].hammaddeler;

      let selectedHammaddeler = ctx.state.allIngredients.filter(
        (ingredient) =>
          ingredient.selected == 1 &&
          !hammaddeler.some((element) => element.ikey == ingredient.ikey)
      );

      let alreadyHammaddeler = ctx.state.allIngredients
        .map((ingredient) => {
          if (ingredient.selected == 1)
            return hammaddeler.filter(
              (element) =>
                element.id == ingredient.ikey &&
                element.rasyonid == ctx.state.selectedSingleFormula.id
            );
        })
        .filter((e) => e && Object.keys(e).length)
        .flat();
      alreadyHammaddeler = [
        ...alreadyHammaddeler,
        ...hammaddeler.filter(
          (x) => x.rasyonid != ctx.state.selectedSingleFormula.id
        ),
      ];

      let tumHammaddeler = [
        ...alreadyHammaddeler,
        ...selectedHammaddeler.map((x) => {
          if (
            !ctx.state.multiFormula[0].hammaddeler.some(
              (element) =>
                element.id == x.ikey &&
                element.rasyonid == ctx.state.selectedSingleFormula.id
            )
          )
            return {
              id: x.ikey,
              rasyonid: ctx.state.selectedSingleFormula.id,
              kod: x.kod,
              hammadde: x.ingredientname,
              min: x.min,
              max: x.max,
              fiyat: x.price,
              aktif: true,
              stok: true,
              karisim: x.karisim,
              minproduction: 0,
              minfiyat: 0,
              maxfiyat: 0,
              oncekikarisim: 0,
              kayitlikarisim: 0,
              onerilenkarisim: 0,
              onerilenfiyat: 0,
              kayitlidanfark: 0,
              oncekindenfark: 0,
              orjkarisim: x.karisim,
              ilkkarisim: x.karisim,
              orjfiyat: x.price,
              oncekifiyat: 0,
              orjmin: x.min,
              oncekimin: 150,
              orjmax: x.max,
              oncekimax: 400,
              rasyonkodu: ctx.state.selectedSingleFormula.code,
            };
        }),
      ];

      ctx.state.multiFormula[0].hammaddeler = tumHammaddeler
        .flat()
        .filter((x) => x);
    }
    ctx.commit("SET_LOADING");
  },
  async updateMultiNutrients(ctx) {
    ctx.commit("SET_LOADING");

    let besinMaddeleri = ctx.state.multiFormula[0].besinMaddeleri;

    let selectedBesinmaddeleri = ctx.state.allNutrients.filter(
      (ingredient) =>
        ingredient.selected == 1 &&
        !besinMaddeleri.some((element) => element.nkey == ingredient.nkey)
    );

    let alreadyBesinmaddeleri = ctx.state.allNutrients
      .map((ingredient) => {
        if (ingredient.selected == 1)
          return besinMaddeleri.filter(
            (element) =>
              element.id == ingredient.nkey &&
              element.rasyonid == ctx.state.selectedSingleFormula.id
          );
      })
      .filter((e) => e && Object.keys(e).length)
      .flat();
    alreadyBesinmaddeleri = [
      ...alreadyBesinmaddeleri,
      ...besinMaddeleri.filter(
        (x) => x.rasyonid != ctx.state.selectedSingleFormula.id
      ),
    ];

    let tumBesinmaddeleri = [
      ...alreadyBesinmaddeleri,
      ...selectedBesinmaddeleri.map((x) => {
        if (
          !ctx.state.multiFormula[0].besinMaddeleri.some(
            (element) =>
              element.id == x.nkey &&
              element.rasyonid == ctx.state.selectedSingleFormula.id
          )
        )
          return {
            id: x.nkey,
            rasyonid: ctx.state.selectedSingleFormula.id,
            kod: x.kod,
            besinmaddesi: x.nutrientname,
            min: x.min,
            max: x.max,
            deger: 0,
            birimadi: x.birimadi,
            tipname: x.tip,
            aktif: true,
            oncekideger: 0,
            kayitlideger: 1,
            onerilendeger: 0,
            onerilenfiyat: 0,
            bmid1: x.bmid1,
            bmid2: x.bmid2,
            kayitlidanfark: 0,
            oncekindenfark: 0,
            orjdeger: 0,
          };
      }),
    ];

    ctx.state.multiFormula[0].besinMaddeleri = tumBesinmaddeleri
      .flat()
      .filter((x) => x);
    ctx.commit("SET_LOADING");
  },
  async solveMultiFormulaService(ctx, { formula, plantid }) {
    var oldTumFormullerValue = formula.tumFormuller.map((x) => ({
      id: x.id,
      karisim: x.karisim,
    }));
    var oldHammaddelerValue = formula.hammaddeler.map((x) => ({
      id: x.id,
      karisim: x.karisim,
    }));
    g;
    var oldBesinmaddeleriValue = formula.besinMaddeleri.map((x) => ({
      id: x.id,
      deger: x.deger,
    }));

    let result = await axios.post(
      "/Multi/SolveMultiGroup/",
      JSON.stringify(formula),
      {
        headers: {
          plantid: plantid,
        },
      }
    );
    const tollerance = 2.842170943040401e-12;
    let responseData = result.data["resultdata"][0];
    //Tüm formüller
    for (let i = 0; i < responseData.tumFormuller.length; i++) {
      responseData.tumFormuller[i].oncekikarisim =
        oldTumFormullerValue.find(
          (x) => x.id == responseData.tumFormuller[i].id
        )?.karisim ?? 0;
      responseData.tumFormuller[i].oncekindenfark =
        responseData.tumFormuller[i].karisim -
        responseData.tumFormuller[i].oncekikarisim;

      responseData.tumFormuller[i].deger = parseFloat(
        responseData.tumFormuller[i].deger
      );
      responseData.tumFormuller[i].min = parseFloat(
        responseData.tumFormuller[i].min
      );
      responseData.tumFormuller[i].max = parseFloat(
        responseData.tumFormuller[i].max
      );
      if (
        (responseData.tumFormuller[i].max != 0 &&
          (responseData.tumFormuller[i].karisim >
            responseData.tumFormuller[i].min ||
            Math.abs(
              responseData.tumFormuller[i].karisim -
                responseData.tumFormuller[i].min
            ) < tollerance) &&
          (responseData.tumFormuller[i].karisim <
            responseData.tumFormuller[i].max ||
            Math.abs(
              responseData.tumFormuller[i].karisim -
                responseData.tumFormuller[i].max
            ) < tollerance)) ||
        (responseData.tumFormuller[i].max == 0 &&
          Math.abs(
            responseData.tumFormuller[i].karisim -
              responseData.tumFormuller[i].min
          ) < tollerance) ||
        responseData.tumFormuller[i].karisim > responseData.tumFormuller[i].min
      ) {
        responseData.tumFormuller[i].itemtextcolor = "Green";
        responseData.tumFormuller[i].itembgcolor = "White";
      } else {
        responseData.tumFormuller[i].itemtextcolor = "Red";
        responseData.tumFormuller[i].itembgcolor = "White";
      }
      if (
        Math.abs(
          responseData.tumFormuller[i].karisim -
            responseData.tumFormuller[i].min
        ) < tollerance
      ) {
        responseData.tumFormuller[i].karisim = Math.round(
          responseData.tumFormuller[i].karisim
        );
      }
      if (
        Math.abs(
          responseData.tumFormuller[i].karisim -
            responseData.tumFormuller[i].max
        ) <= tollerance
      ) {
        responseData.tumFormuller[i].karisim = Math.round(
          responseData.tumFormuller[i].karisim
        );
      }
    }
    //Hammaddeler
    for (let i = 0; i < responseData.hammaddeler.length; i++) {
      responseData.hammaddeler[i].oncekikarisim =
        oldHammaddelerValue.find((x) => x.id == responseData.hammaddeler[i].id)
          ?.karisim ?? 0;
      responseData.hammaddeler[i].oncekindenfark =
        responseData.hammaddeler[i].karisim -
        responseData.hammaddeler[i].oncekikarisim;

      responseData.hammaddeler[i].deger = parseFloat(
        responseData.hammaddeler[i].deger
      );
      responseData.hammaddeler[i].min = parseFloat(
        responseData.hammaddeler[i].min
      );
      responseData.hammaddeler[i].max = parseFloat(
        responseData.hammaddeler[i].max
      );
      if (
        (responseData.hammaddeler[i].max != 0 &&
          (responseData.hammaddeler[i].karisim >
            responseData.hammaddeler[i].min ||
            Math.abs(
              responseData.hammaddeler[i].karisim -
                responseData.hammaddeler[i].min
            ) < tollerance) &&
          (responseData.hammaddeler[i].karisim <
            responseData.hammaddeler[i].max ||
            Math.abs(
              responseData.hammaddeler[i].karisim -
                responseData.hammaddeler[i].max
            ) < tollerance)) ||
        (responseData.hammaddeler[i].max == 0 &&
          Math.abs(
            responseData.hammaddeler[i].karisim -
              responseData.hammaddeler[i].min
          ) < tollerance) ||
        responseData.hammaddeler[i].karisim > responseData.hammaddeler[i].min
      ) {
        responseData.hammaddeler[i].itemtextcolor = "Green";
        responseData.hammaddeler[i].itembgcolor = "White";
      } else {
        responseData.hammaddeler[i].itemtextcolor = "Red";
        responseData.hammaddeler[i].itembgcolor = "White";
      }
      if (
        Math.abs(
          responseData.hammaddeler[i].karisim - responseData.hammaddeler[i].min
        ) < tollerance
      ) {
        responseData.hammaddeler[i].karisim = Math.round(
          responseData.hammaddeler[i].karisim
        );
      }
      if (
        Math.abs(
          responseData.hammaddeler[i].karisim - responseData.hammaddeler[i].max
        ) <= tollerance
      ) {
        responseData.hammaddeler[i].karisim = Math.round(
          responseData.hammaddeler[i].karisim
        );
      }
    }
    //Besin maddeleri

    for (let i = 0; i < responseData.besinMaddeleri.length; i++) {
      responseData.besinMaddeleri[i].oncekideger =
        oldBesinmaddeleriValue.find(
          (x) => x.id == responseData.besinMaddeleri[i].id
        )?.deger ?? 0;
      responseData.hammaddeler[i].oncekindenfark =
        responseData.hammaddeler[i].deger -
        responseData.hammaddeler[i].oncekideger;

      responseData.besinMaddeleri[i].deger = parseFloat(
        responseData.besinMaddeleri[i].deger
      );
      responseData.besinMaddeleri[i].min = parseFloat(
        responseData.besinMaddeleri[i].min
      );
      responseData.besinMaddeleri[i].max = parseFloat(
        responseData.besinMaddeleri[i].max
      );

      responseData.besinMaddeleri[i].deger =
        responseData.besinMaddeleri[i].deger ?? 0;
      responseData.besinMaddeleri[i].max =
        responseData.besinMaddeleri[i].max ?? 0;
      responseData.besinMaddeleri[i].min =
        responseData.besinMaddeleri[i].min ?? 0;

      if (
        (responseData.besinMaddeleri[i].max != 0 &&
          (responseData.besinMaddeleri[i].deger >
            responseData.besinMaddeleri[i].min ||
            Math.abs(
              responseData.besinMaddeleri[i].deger -
                responseData.besinMaddeleri[i].min
            ) < tollerance) &&
          responseData.besinMaddeleri[i].deger <
            responseData.besinMaddeleri[i].max) ||
        Math.abs(
          responseData.besinMaddeleri[i].deger -
            responseData.besinMaddeleri[i].max
        ) < tollerance ||
        (responseData.besinMaddeleri[i].max == 0 &&
          (responseData.besinMaddeleri[i].deger >
            responseData.besinMaddeleri[i].min ||
            Math.abs(
              responseData.besinMaddeleri[i].deger -
                responseData.besinMaddeleri[i].min
            ) < tollerance))
      ) {
        responseData.besinMaddeleri[i].itemtextcolor = "Green";
        responseData.besinMaddeleri[i].itembgcolor = "White";
      } else {
        responseData.besinMaddeleri[i].itemtextcolor = "Red";
        responseData.besinMaddeleri[i].itembgcolor = "White";
      }
      if (
        Math.abs(
          responseData.besinMaddeleri[i].deger -
            responseData.besinMaddeleri[i].min
        ) < tollerance
      ) {
        responseData.besinMaddeleri[i].deger = Math.round(
          responseData.besinMaddeleri[i].deger
        );
      }
      if (
        Math.abs(
          responseData.besinMaddeleri[i].deger -
            responseData.besinMaddeleri[i].max
        ) < tollerance
      ) {
        responseData.besinMaddeleri[i].deger = Math.round(
          responseData.besinMaddeleri[i].deger
        );
      }
    }

    var status = result.data["status"];
    ctx.commit("SET_MULTI_COZDU", responseData.cozdu ?? false);

    if (status == "OK") {
      ctx.commit("SET_MULTI_FORMULA", result.data["resultdata"]);

      return responseData;
    } else if (status == "ERROR") {
      if (result.data["resultdata"] != null) {
        ctx.commit("SET_MULTI_FORMULA", result.data["resultdata"]);
      }
      var responseFormula = responseData;
      responseFormula.cozdu = false;
      return responseFormula;
    } else if (status == "NOFEASIBLE") {
      var responseFormula = responseData;
      ctx.commit("SET_MULTI_FORMULA", result.data["resultdata"]);
      return responseFormula;
    }
  },
};
