import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchCompanyProducts(ctx, plantid) {
      var response = await axios.get(`/Products/GetCompanyProducts/`, {
        headers: {
          culinfo: getUserData().cultureinfo,
          plantid: plantid,
          tip: "Company",
        },
      });
      if (response.data.status != "OK") return null;

      return response.data.resultdata;
    },

    async deleteCompanyProduct(ctx, ingredientId) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Ingredients/DeleteIngredient/${ingredientId}`,
              {
                headers: { culinfo: getUserData().cultureinfo, plantid: "0" },
              }
            );
            return response.data.status == "OK";
          } else return false;
        });
    },

    async addCompanyProduct(ctx, newProduct) {
      var response = await axios.post(
        "/Ingredients/addingredient",
        newProduct,
        {
          headers: {
            culinfo: getUserData().cultureinfo,
            tip: "Ingredient",
          },
        }
      );
      return {
        status: response.data.status == "OK",
        message: response.data.statusdata,
      };
    },
    async updateCompanyProduct(ctx, { ingredient, values }) {
      var response = await axios.put(
        `/Ingredients/UpdateIngredient/${ingredient.id}`,
        ingredient,
        {
          headers: {
            culinfo: getUserData().cultureinfo,
          },
        }
      );
      if (response.data.status == "OK") {
        var result = await axios.put(
          `/Ingredients/UpdateDegerler/${ingredient.id}`,
          values,
          {
            headers: {
              culinfo: getUserData().cultureinfo,
            },
          }
        );
      }
      return result.data.status == "OK";
    },
  },
};
