import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";
import store from "@/store/index";
export const additionalValuesState = {
  additionalValues: [],
  additionalValueLoading: false,
  values: [],
};

export const additionalValuesMutations = {
  SET_ADDITIONAL_VALUES(state, payload) {
    state.additionalValues = payload;
  },
  SET_LOADING(state) {
    state.additionalValueLoading = !state.additionalValueLoading;
  },
  SET_VALUES(state, payload) {
    state.values = payload;
  },
};
export const additionalValuesActions = {
  async getCurrentValues(ctx) {
    ctx.commit("SET_LOADING");
    ctx.commit("SET_VALUES", ctx.state.selectedFormula.besinmaddeleri);
    await store.dispatch("plantsModule/getAdditionalValues");
    ctx.commit(
      "SET_ADDITIONAL_VALUES",
      store.state.plantsModule.additionalValues
    );

    ctx.state.values.map((aV) => ctx.dispatch("checkEquations", aV.kod));
    ctx.dispatch("checkEquations");
    ctx.commit("SET_LOADING");
  },
  async checkEquations(ctx, kod) {
    const filteredEsitlikler = [...ctx.state.additionalValues];
    let values = [...ctx.state.values];

    const regex = /\[(.*?)\]/g;
    let errorList = [];
    filteredEsitlikler.map((x) => {
      // if (!!(x.formul.split("[B" + kod + "]").length - 1)) {
      let formul = x.formul;
      errorList = [];

      const besinMadddeleriInString = formul.match(regex);

      const mathString = besinMadddeleriInString.map((m) => {
        let value = 0;
        let name = "";
        try {
          value = values.find(
            (y) => y.kod == m.replace("[B", "").replace("]", "")
          ).deger;
        } catch (error) {
          value = 0;
          errorList.push(m);
        }

        var hasValue = !!parseFloat(value);
        return (formul = formul
          .replaceAll(m, hasValue ? value : 0)
          .replaceAll(",", "."));
      });

      x.deger = !errorList.length
        ? eval(mathString[mathString.length - 1]).toFixed(
            getUserData().nutrientdecimal
          )
        : 0;
      if (errorList.length && ctx.state.selectedFormulaNutrients.length)
        errorList = errorList.map((e) => {
          return (
            ctx.state.selectedFormulaNutrients.find(
              (y) => y.kod == e.replace("[B", "").replace("]", "")
            )?.nutrientname ?? e
          );
        });
      x.hata = !!errorList.length;
      x.errorList = errorList;

      ctx.commit("SET_VALUES", values);
      // }
    });
    ctx.commit("SET_ADDITIONAL_VALUES", filteredEsitlikler);
    return errorList;
  },
};
